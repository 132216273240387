// import Image from 'next/image'
import Image from 'next/image'
import React from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import ButtonRounded from '../../shared/ButtonRounded'
import { ConditionalBR } from '../../shared/ConditionalBR'
// import ButtonRounded from '../../shared/ButtonRounded'
import { Container } from '../../shared/Container'
import DualLangHeading from '../../shared/DualLangHeading'
import Grid from '../../shared/Grid'
import Heading from '../../shared/Heading'
import Stack from '../../shared/Stack'

export const IntroductionSection: React.FC = () => (
  <StyledDiv>
    <Container size="large">
      <SectionHead>
        <Stack gap="10">
          <Heading label="FOR USERS" size="20" color="blue" $mobileSize="14" />
          <DualLangHeading
            en="RYDE PASS App"
            ja="RYDE PASSで新しい移動体験を"
          />
        </Stack>
      </SectionHead>
      <GridWrap>
        <Grid by={3} $tablet={1} $mobile={1}>
          <div>
            <ItemHead>
              <ItemIndex>
                <span>1</span>
              </ItemIndex>
              <ItemTitle>
                <span>アナログだった地域交通がデジタルに。</span>
              </ItemTitle>
            </ItemHead>
            <ItemThumb>
              <Image
                src="/images/picture/top_intro_01.jpg"
                fill
                style={{ objectFit: 'cover' }}
                alt=""
                sizes="33vw"
              />
            </ItemThumb>
            <ItemDescription>
              <p>
                いつも乗っている電車やバスがスマホで乗れるように。デジタルチケットなのでいつでもどこでも購入が可能です。
              </p>
            </ItemDescription>
          </div>
          <div>
            <ItemHead>
              <ItemIndex>
                <span>2</span>
              </ItemIndex>
              <ItemTitle>
                <span>様々な移動を一つのアプリで利用可能に。</span>
              </ItemTitle>
            </ItemHead>
            <ItemThumb>
              <Image
                src="/images/picture/top_intro_02.jpg"
                fill
                style={{ objectFit: 'cover' }}
                alt=""
                sizes="33vw"
              />
            </ItemThumb>
            <ItemDescription>
              <p>
                日常から観光まで、地域の移動はこのアプリ一つでOK。シェアサイクルなどもRYDE
                PASSから利用可能です。
              </p>
            </ItemDescription>
          </div>
          <div>
            <ItemHead>
              <ItemIndex>
                <span>3</span>
              </ItemIndex>
              <ItemTitle>
                <span>90以上の交通で利用可能。エリア拡大中。</span>
              </ItemTitle>
            </ItemHead>
            <ItemThumb>
              <Image
                src="/images/picture/top_intro_03.jpg"
                fill
                style={{ objectFit: 'cover' }}
                alt=""
                sizes="33vw"
              />
            </ItemThumb>
            <ItemDescription>
              <p>
                フリーパス、定期券、観光施設で使えるお得なチケットがRYDE
                PASSで利用可能。各都道府県さまざまなエリアに拡大中。
              </p>
            </ItemDescription>
          </div>
        </Grid>
      </GridWrap>
      <BtnAera>
        <ButtonRounded
          href="https://pass.ryde-go.com/"
          color="lightblue"
          height="72"
          icon="arrow"
        >
          使い方やチケットを
          <ConditionalBR $tablet={true} $desktop={false} />
          詳しく見る
        </ButtonRounded>
      </BtnAera>
    </Container>
  </StyledDiv>
)

const StyledDiv = styled.div`
  position: relative;
  margin-top: 132px;
  padding: 83px 0 360px 0;
  background-color: #e6eefa;

  ${Media.mobileMiddle} {
    margin-top: 80px;
    padding: 34px 0 280px 0;
  }
`
const SectionHead = styled.div`
  margin-bottom: 54px;

  ${Media.mobileMiddle} {
    margin-bottom: 44px;
  }
`

const ItemHead = styled.div`
  display: flex;
`

const ItemIndex = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  ${theme.typography.FontOverpassBlack}
  width: 60px;
  color: #1e9af3;
  font-size: 48px;
  span {
    display: block;
    transform: translateY(0.1em);
  }
`

const ItemTitle = styled.div`
  display: flex;
  align-items: center;
  ${theme.typography.FontNotoBold}
  font-size: 18px;
  line-height: 1.66;
`

const ItemThumb = styled.div`
  position: relative;
  aspect-ratio: 320 / 240;
  margin-top: 22px;
  overflow: hidden;
  border-radius: 16px 16px;
`

const ItemDescription = styled.div`
  margin-top: 12px;
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 2;
`

const GridWrap = styled.div`
  ${Media.mobileTablet} {
    & > div > div + div {
      margin-top: 50px;
    }
  }
`

const BtnAera = styled.div`
  width: 496px;
  margin: 40px auto 0 auto;
  a {
    font-size: 20px;
  }

  ${Media.mobileMiddle} {
    width: 100%;
    a {
      font-size: 16px;
      line-height: 1.45;
      text-align: center;
    }
  }
`
