import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import Image from 'next/image'
import React, { FC, useState } from 'react'
import Slick from 'react-slick'
import styled from 'styled-components'
import useMedia from 'use-media'

import Media, { MOBILE_WIDTH } from '../../../utils/Media'

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
  pauseOnHover: false,
  slidesToShow: 1,
  fade: true,
}

type Props = {
  images: string[]
  onLoadSlider: Function
}

export const Slider: FC<Props> = ({ images, onLoadSlider }) => {
  const [isShow, setIsShow] = useState(false)
  const showSlider = () => {
    setIsShow(true)
    onLoadSlider()
  }

  return (
    <Wrap data-show={isShow}>
      <StyledSlider
        {...settings}
        onInit={() => {
          setTimeout(() => {
            showSlider()
          }, 100)
        }}
      >
        {images.map((image) => (
          <SliderItem key={image}>
            <SliderImage src={image} alt={image} />
          </SliderItem>
        ))}
      </StyledSlider>
    </Wrap>
  )
}

const Wrap = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  /* prettier-ignore */
  &[data-show='true'] { /* stylelint-disable-line */
    .slick-slider {
      opacity: 1;
    }
  }
`

const SliderItem = styled.div`
  position: absolute;
  height: 100%;

  & > div {
    height: 100%;
  }
`

const SliderImage: React.FC<{
  src: string
  alt: string
}> = ({ src, alt }) => {
  const isMobile = useMedia({ maxWidth: MOBILE_WIDTH })

  return isMobile ? (
    <Image src={src} alt={alt} fill style={{ objectFit: 'cover' }} />
  ) : (
    <Image src={src} alt={alt} fill style={{ objectFit: 'cover' }} />
  )
}

const StyledSlider = styled(Slick)`
  * {
    outline: none;
  }

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.7s cubic-bezier(0.42, 0, 0.58, 1);
  opacity: 0;

  .slick-list,
  .slick-track {
    height: 100%;
  }

  .slick-slide {
    position: absolute !important;
    left: 0 !important;
    height: 100%;

    & > div {
      height: 100%;
    }
  }
  .slick-dots {
    display: flex;
    position: absolute;
    bottom: 30px;
    justify-content: center;

    li {
      width: 16px;
      height: 16px;
      margin: 0 16px;
    }

    button {
      width: 100%;
      height: 100%;
      padding: 0;
      border-radius: 50% 50%;
      background-color: #fff;

      &::before {
        content: '';
        display: none;
      }
    }
    .slick-active {
      button {
        background-color: #1e9af3;
      }
    }
  }
  ${Media.mobileMiddle} {
    .slick-dots {
      bottom: 20px;
      li {
        width: 12px;
        height: 12px;
        margin: 0 6px;
      }
    }
  }
`
