import Image from 'next/image'
import React, { useState } from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import ButtonRounded from '../../shared/ButtonRounded'
import { Container } from '../../shared/Container'
import DualLangHeading from '../../shared/DualLangHeading'

export const VisionSection: React.FC = () => {
  const [loadComplete, setLoadComplete] = useState(false)

  const onLoadCompleteImage = () => {
    setLoadComplete(true)
  }
  return (
    <StyledDiv data-load-complete={loadComplete}>
      <Container size="large">
        <DualLangHeading en="VISION" ja="私たちが目指すもの" />
        <Grid>
          <ImgWrap>
            <ImgWrapInner $onload={loadComplete}>
              <Image
                src="/images/picture/top_vision.jpg"
                fill
                style={{ objectFit: 'cover', objectPosition: 'left top' }}
                alt=""
                onLoad={() => {
                  onLoadCompleteImage()
                }}
              />
            </ImgWrapInner>
          </ImgWrap>
          <BodyWrap>
            <LineWrap>
              <Image
                src="/images/svg/line.svg"
                width="2062"
                height="1651"
                alt=""
                onLoad={() => {
                  onLoadCompleteImage()
                }}
              />
            </LineWrap>
            <Title>
              地域交通をデジタルで持続可能な未来へ。
              <br />
              一人ひとりに移動を通じた新たな体験を。
            </Title>
            <Text>
              地域の人々の生活を支える移動手段、いわゆる「地域交通 /
              二次交通」が人口減少や社会のデジタル化によって大きな変革期に入っています。
              そこで私たちは地域交通に対してデジタル化を促進し、持続可能な交通を実現していきます。
              それは、私たちのサービスを通して交通の簡単なデジタル化を提供し、従来の公共交通や新たなモビリティサービスをもつなぐことで、移動を簡単にしていきます。
              これにより地域は活性化し、人々は国内をより自由に移動し、新たな体験ができるようになります。
              私たちは移動を通じて、地域や一人ひとりの日常と未来をより良いものへ変えていくことを目指しています。
            </Text>
          </BodyWrap>
        </Grid>
        <LinkButton>
          <ButtonRounded href="/about" color="blue" icon="arrow" height="46">
            詳しく見る
          </ButtonRounded>
        </LinkButton>
      </Container>
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  position: relative;
  margin-top: 132px;

  ${Media.mobileMiddle} {
    margin-top: 80px;
    overflow: hidden;
  }
`

const Grid = styled.div`
  display: flex;
  gap: 32px;
  margin-top: 40px;

  ${Media.mobileTablet} {
    flex-direction: column;
  }
`

const ImgWrap = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: flex-end;
  width: 50%;
  height: 500px;

  ${Media.mobileTablet} {
    width: 100%;
    height: 242px;
  }
`

const ImgWrapInner = styled.div<{ $onload: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  width: calc(var(--vw) * 50 - 16px);
  height: 100%;
  overflow: hidden;
  transition: opacity 0.8s ${theme.easing.easeOutSine};
  border-radius: 0 16px 16px 0;
  opacity: ${(props) => (props.$onload ? 1 : 0)};

  ${Media.mobileTablet} {
    width: calc(var(--vw) * 100 - 20px);
  }
`
const BodyWrap = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: 50%;

  ${Media.mobileTablet} {
    width: 100%;
  }
`

const Title = styled.div`
  position: relative;
  ${theme.typography.FontNotoBold}
  font-size: 20px;
  line-height: 2;

  ${Media.mobileTablet} {
    line-height: 1.8;
  }
`
const Text = styled.div`
  position: relative;
  margin-top: 27px;
  font-size: 16px;
  line-height: 2;

  ${Media.mobileTablet} {
    margin-top: 22px;
  }
`
const LinkButton = styled.div`
  width: 320px;
  margin-left: auto;
  padding-top: 64px;

  ${Media.mobileTablet} {
    width: 100%;
    padding-top: 40px;
  }
`
const LineWrap = styled.div`
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;

  img {
    width: 140vw;
    height: auto;
    transform: translate(-50%, -50%);
  }

  ${Media.mobileTablet} {
    top: 14%;

    img {
      width: 250vw;
    }
  }
`
