import React from 'react'

import { Maybe, NewsCollectionFieldsFragment } from '../../../generated/graphql'
import Layout from '../../shared/Layout'
// import { AboutSection } from './AboutSection'
// import { CasestudySection } from './CasestudySection'
// import { HeroSection } from './HeroSection'
import { IntroductionSection } from './IntroductionSection'
import { NewsSection } from './NewsSection'
// import { PointSection } from './PointSection'
import { SlideshowSection } from './SlideshowSection'
import { VisionSection } from './VisionSection'

interface StaticNewIndexProps {
  newsItems: Maybe<NewsCollectionFieldsFragment>[]
  notePosts: any
}

const NewIndexTemplate: React.FC<StaticNewIndexProps> = ({
  newsItems,
  notePosts,
}) => {
  let noteContents: any[] = notePosts.contents
  if (noteContents.length > 3) {
    noteContents = noteContents.slice(0, 3)
  }
  return (
    <Layout>
      <SlideshowSection />
      <NewsSection newsItems={newsItems} />
      <VisionSection />
      <IntroductionSection />
    </Layout>
  )
}

export default NewIndexTemplate
