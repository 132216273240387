import React, { useState } from 'react'
import styled from 'styled-components'

import theme from '../../../theme'
import Media from '../../../utils/Media'
import { ConditionalBR } from '../../shared/ConditionalBR'
import { Container } from '../../shared/Container'
import { Slider } from './Slider'

const images = [
  '/images/hero/slide_01.jpg',
  '/images/hero/slide_02.jpg',
  '/images/hero/slide_03.jpg',
  '/images/hero/slide_04.jpg',
]

export const SlideshowSection: React.FC = () => {
  const [loadComplete, setLoadComplete] = useState(false)

  const onLoadSlider = () => {
    setLoadComplete(true)
  }
  return (
    <Wrap>
      <Container>
        <SliderWrap>
          <Slider images={images} onLoadSlider={onLoadSlider} />
        </SliderWrap>
        <TextWrap>
          <BorderWrap>
            <Border $onload={loadComplete} />
          </BorderWrap>
          <Label $onload={loadComplete}>
            地域交通を簡単に、
            <span className="min-spacing" />
            <ConditionalBR $tablet={false} $desktop={false} $mobile={true} />
            社会を豊かに。
          </Label>
        </TextWrap>
      </Container>
    </Wrap>
  )
}

const Wrap = styled.div`
  position: relative;

  & > div {
    position: relative;
  }
`

const SliderWrap = styled.div`
  position: relative;
  width: calc(var(--vw) * 50 + 50% - 170px);
  height: 650px;
  margin-left: 170px;
  overflow: hidden;
  border-radius: 16px 0 0 16px;
  background-color: #efefef;

  ${Media.mobileMiddle} {
    width: calc(var(--vw) * 50 + 50% - 54px);
    height: 440px;
    margin-left: 54px;
  }
`
const TextWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 53%;
  left: 32px;
  align-items: center;
  width: calc(100% - 64px);
  height: 104px;

  ${Media.tablet} {
    width: auto;
  }

  ${Media.mobile} {
    bottom: 45%;
    left: 20px;
    width: calc(100% - 40px);
  }
`
const BorderWrap = styled.div`
  position: absolute;
  top: 0;
  right: calc(100% - 580px);
  width: calc(calc(var(--vw) * 100 - 100%) / 2 + 580px);
  height: 100%;
  overflow: hidden;

  ${Media.tablet} {
    right: 0;
    width: calc(100% + 32px);
  }

  ${Media.mobile} {
    right: calc(100% - 278px);
    width: calc(calc(var(--vw) * 100 - 100%) / 2 + 278px);
  }
`

const Border = styled.div<{ $onload: boolean }>`
  width: 100%;
  height: 100%;
  transform: ${(props) =>
    props.$onload ? 'translateX(0%)' : 'translateX(-100%)'};
  transition: transform 0.8s ${theme.easing.easeOutExpo} 0.4s;
  border-radius: 0 99999px 99999px 0;
  background-color: #1e9af3;
`

const Label = styled.span<{ $onload: boolean }>`
  position: relative;
  ${theme.typography.FontNotoBold}
  transition: opacity 0.4s ${theme.easing.easeOut} 0.8s;
  opacity: ${(props) => (props.$onload ? '1' : '0')};
  color: #fff;
  font-size: 32px;
  white-space: nowrap;

  .min-spacing {
    margin-left: -0.5em;
  }

  ${Media.mobileMiddle} {
    font-size: 24px;
    line-height: 2;
  }
`
