import Link from 'next/link'
import React from 'react'
import styled from 'styled-components'

import { Maybe, NewsCollectionFieldsFragment } from '../../../generated/graphql'
import theme from '../../../theme'
import toDisplayDateText from '../../../utils/DateFormat'
import Media from '../../../utils/Media'
import ButtonRounded from '../../shared/ButtonRounded'
import { Container } from '../../shared/Container'
import DualLangHeading from '../../shared/DualLangHeading'

type Props = {
  newsItems: Maybe<NewsCollectionFieldsFragment>[]
}

export const NewsSection: React.FC<Props> = ({ newsItems }) => (
  <StyledDiv>
    <Container size="large">
      <DualLangHeading en="NEWS" ja="お知らせ" />
      <List>
        {newsItems.map((item) => {
          if (item !== null) {
            const { sys } = item
            const id = (sys as any)?.id
            const date = (item as any)?.date
            const title = (item as any)?.title
            const url = `/news/${id}`

            return (
              <li key={id}>
                <NewsListItem href={url} passHref>
                  <NewsListItemTag>
                    <Tag>お知らせ</Tag>
                  </NewsListItemTag>
                  <NewsListItemDate>{toDisplayDateText(date)}</NewsListItemDate>
                  <NewsListItemTitle>
                    <p>{title}</p>
                  </NewsListItemTitle>
                </NewsListItem>
              </li>
            )
          }
          return <></>
        })}
      </List>

      <LinkButton>
        <ButtonRounded
          href="/news?page=1"
          color="blue"
          icon="arrow"
          height="46"
        >
          お知らせ一覧へ
        </ButtonRounded>
      </LinkButton>
    </Container>
  </StyledDiv>
)

const LinkButton = styled.div`
  width: 320px;
  margin-left: auto;
  padding-top: 64px;

  ${Media.mobileTablet} {
    width: 100%;
    padding-top: 40px;
  }
`

const List = styled.ul`
  margin-top: 40px;
  & > li {
    border-bottom: 1px solid #000;
  }
`

const NewsListItemTag = styled.div`
  display: flex;
  align-items: center;
  width: 176px;
`
const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 144px;
  height: 30px;
  padding: 0 24px;
  border: 1px solid #000;
  border-radius: 15px 15px;
  font-size: 14px;
  font-weight: 700;

  ${Media.mobileMiddle} {
    width: 50%;
  }
`
const NewsListItemDate = styled.div`
  ${theme.typography.DefNotoSansBody}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 176px;

  ${Media.mobileMiddle} {
    position: absolute;
    top: 18px;
    right: 0;
    justify-content: flex-end;
    width: 50%;
  }
`
const NewsListItemTitle = styled.div`
  ${theme.typography.DefNotoSansBody}
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 352px);

  ${Media.mobileMiddle} {
    width: 100%;
    margin-top: 10px;
  }
`

const StyledDiv = styled.div`
  position: relative;
  z-index: 1;
  margin-top: 76px;

  ${Media.mobileMiddle} {
    margin-top: 103px;
  }
`
const NewsListItem = styled(Link)`
  display: flex;
  position: relative;
  align-items: center;
  min-height: 80px;
  padding: 6px 0;
  color: #000;
  text-decoration: none;

  ${Media.mobileMiddle} {
    flex-wrap: wrap;
    padding: 18px 0 6px 0;
  }

  &:hover {
    color: #1e9af3;

    ${Tag} {
      background-color: #000;
      color: #fff;
    }
  }
`
