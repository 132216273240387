import React from 'react'

import SEO from '../components/shared/SEO'
import NewIndexTemplate from '../components/templates/NewIndexTemplate'
import { TOP_NEWS_LIMIT } from '../config/settings'
import {
  Maybe,
  NewsCollectionDocument,
  NewsCollectionFieldsFragment,
  NewsCollectionQuery,
  NewsOrder,
} from '../generated/graphql'
import { initializeApollo } from '../utils/ApolloClient'
import { getNotePosts } from '../utils/Note'

type Props = {
  newsItems: Maybe<NewsCollectionFieldsFragment>[]
  notePosts: any
}

const Index: React.FC<Props> = ({ newsItems, notePosts }) => (
  <>
    <SEO
      title="RYDE株式会社 | 二次交通をわかりやすく、使いやすく、そして持続可能に"
      description="RYDE株式会社は二次交通のデジタル化と、その活用を通じた社会問題の解決を目指し、モビリティプラットフォームを企画・開発しています。"
      openGraph={{
        images: [
          {
            url: 'https://ryde-inc.jp/og_image.png',
          },
        ],
        type: 'website',
        url: 'https://ryde-inc.jp/',
        site_name:
          'RYDE株式会社 | 二次交通をわかりやすく、使いやすく、そして持続可能に',
        locale: 'ja_JP',
      }}
    />
    <NewIndexTemplate newsItems={newsItems} notePosts={notePosts} />
  </>
)

export async function getStaticProps() {
  const apolloClient = initializeApollo()

  /* 
  const { data, error } = await apolloClient.query<NewsCollectionQuery>({
    query: NewsCollectionDocument,
    variables: {
      order: [NewsOrder.DateDesc],
      limit: TOP_NEWS_LIMIT,
    },
  })

  if (error || !data.newsCollection) {
    throw error
  }
  */
  const data = await Promise.all([
    apolloClient.query<NewsCollectionQuery>({
      query: NewsCollectionDocument,
      variables: {
        order: [NewsOrder.DateDesc],
        limit: TOP_NEWS_LIMIT,
      },
    }),
    getNotePosts(),
  ])

  return {
    props: {
      newsItems: data[0].data.newsCollection?.items,
      notePosts: data[1].data,
    } as Props,
    revalidate: 60,
  }
}

export default Index
